import React, { useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; 
import { BrowserRouter, useLocation } from "react-router-dom";
import Navigation from "./routes/routes";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer"; 

function App() { 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.pathname]);
  return (
    <BrowserRouter>
      <ToastContainer /> 
      <div className="our-web-app">
        <Navigation />
        <a target="_blank" rel="noopener" href="https://wa.me/971561335667">
          <svg
            className="our-web-app__svg"
            width="50"
            height="50"
            viewBox="0 0 50 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_607_516)">
              <path
                d="M49.9971 37.3601C49.9971 37.6338 49.9888 38.2276 49.9723 38.6862C49.9321 39.8084 49.8431 41.2565 49.7086 41.9163C49.5064 42.9078 49.2012 43.8436 48.8032 44.6226C48.3324 45.544 47.7318 46.3694 47.0181 47.0817C46.306 47.7927 45.4814 48.3909 44.5613 48.8599C43.7783 49.2589 42.8366 49.5643 41.8395 49.7656C41.1862 49.8975 39.749 49.9847 38.634 50.0244C38.1749 50.0408 37.5811 50.049 37.3083 50.049L12.6879 50.0451C12.4141 50.0451 11.8205 50.0367 11.3618 50.0202C10.2396 49.98 8.79155 49.891 8.13174 49.7565C7.14023 49.5543 6.20435 49.2491 5.42544 48.8512C4.50395 48.3803 3.67861 47.7797 2.96626 47.066C2.25532 46.3539 1.65708 45.5293 1.18818 44.6093C0.789063 43.8262 0.483691 42.8845 0.282373 41.8874C0.150537 41.2342 0.0632813 39.797 0.023584 38.6819C0.00727539 38.2229 -0.000976562 37.629 -0.000976562 37.3563L0.00292969 12.7358C0.00292969 12.462 0.0112793 11.8683 0.0277344 11.4097C0.0680176 10.2875 0.156934 8.8394 0.291455 8.17964C0.493652 7.18814 0.798828 6.25225 1.19683 5.47329C1.66768 4.55186 2.26831 3.72646 2.98193 3.01411C3.69404 2.30322 4.51865 1.70493 5.43872 1.23599C6.22178 0.836963 7.16353 0.531592 8.16064 0.330273C8.81382 0.198389 10.2511 0.111133 11.3661 0.0714844C11.8252 0.055127 12.4191 0.046875 12.6917 0.046875L37.3122 0.0508301C37.586 0.0508301 38.1797 0.0591797 38.6383 0.0756348C39.7605 0.115918 41.2085 0.204834 41.8684 0.339355C42.8599 0.541553 43.7958 0.846728 44.5747 1.24473C45.4961 1.71558 46.3215 2.31621 47.0338 3.02983C47.7447 3.74194 48.343 4.5665 48.8119 5.48662C49.211 6.26968 49.5164 7.21138 49.7177 8.2085C49.8496 8.86172 49.9368 10.2989 49.9765 11.414C49.9929 11.8731 50.0011 12.4669 50.0011 12.7397L49.9971 37.3601V37.3601Z"
                fill="url(#paint0_linear_607_516)"
              />
              <path
                d="M38.2469 11.8778C34.8617 8.48979 30.3597 6.623 25.5631 6.62109C15.6802 6.62109 7.63672 14.6615 7.63276 24.5445C7.63149 27.7037 8.45713 30.7874 10.0262 33.5056L7.48242 42.7941L16.9875 40.3016C19.6065 41.7295 22.555 42.482 25.556 42.4833H25.5634C35.4453 42.4833 43.4896 34.4419 43.4936 24.5588C43.4955 19.7694 41.6322 15.2659 38.2469 11.8778ZM25.5634 39.456H25.5573C22.8831 39.455 20.2603 38.7368 17.9721 37.3794L17.4279 37.0565L11.7874 38.5357L13.293 33.038L12.9385 32.4744C11.4467 30.1023 10.6588 27.3606 10.66 24.5457C10.6632 16.3314 17.3487 9.64844 25.5691 9.64844C29.5497 9.64995 33.2915 11.2017 36.1052 14.0177C38.9189 16.8336 40.4676 20.5769 40.4662 24.5577C40.4629 32.7727 33.7775 39.456 25.5634 39.456ZM33.7379 28.298C33.2898 28.0739 31.0873 26.9903 30.6766 26.8409C30.2659 26.6914 29.9673 26.6166 29.6685 27.0651C29.3699 27.5134 28.5113 28.5223 28.2499 28.8211C27.9886 29.1201 27.7272 29.1575 27.2792 28.9333C26.8312 28.7091 25.3876 28.2361 23.6764 26.7103C22.3446 25.5229 21.4454 24.0561 21.1841 23.6076C20.9228 23.1592 21.1563 22.9169 21.3806 22.6936C21.5821 22.4929 21.8286 22.1705 22.0526 21.9089C22.2766 21.6475 22.3513 21.4605 22.5006 21.1617C22.65 20.8627 22.5752 20.6012 22.4632 20.377C22.3513 20.1528 21.4552 17.9483 21.0819 17.0516C20.7183 16.1783 20.3488 16.2964 20.0738 16.2827C19.8128 16.2697 19.5138 16.267 19.2152 16.267C18.9166 16.267 18.4312 16.3791 18.0205 16.8274C17.6099 17.2758 16.4525 18.3594 16.4525 20.5638C16.4525 22.7683 18.0578 24.898 18.2818 25.1969C18.5059 25.4958 21.441 30.0197 25.9352 31.9597C27.0042 32.4211 27.8386 32.6967 28.4893 32.9031C29.5625 33.2439 30.5392 33.1958 31.3112 33.0806C32.172 32.952 33.9618 31.9971 34.3353 30.9509C34.7086 29.9047 34.7086 29.008 34.5966 28.8212C34.4846 28.6344 34.1859 28.5223 33.7379 28.298Z"
                fill="white"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_607_516"
                x1="25"
                y1="0.046875"
                x2="25"
                y2="50.0491"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#61FD7D" />
                <stop offset="1" stop-color="#2BB826" />
              </linearGradient>
              <clipPath id="clip0_607_516">
                <rect width="50" height="50" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </a>
      </div>
      {/* {window.location.pathname === "/" ? null : <Footer />} */}
    </BrowserRouter>
  );
}

export default App;
