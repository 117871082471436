import React, { useState } from "react";
import WhiteLogo from "../../assets/whitelogo.svg";
import PneumaticImg from "../../assets/Pneumatic.png";
import Steam1 from "../../assets/steam1.png";
import RegisterModal from "../../Modals/RegisterModal";
import Footer from "../../components/Footer";
const Pneumatic = () => {
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  return (
    <>
      <div className="ss-page | single-service">
        <RegisterModal
          service="Pneumatic Accessories"
          open={open2}
          handleClose={handleClose2}
        />
        <div className="logo-single">
          <img src={WhiteLogo} alt="" />
        </div>
        <div className="ss-page__content | single-service-content">
          <div className="ss-page__content-text | single-service-content-text">
            <h1>Pneumatic Accessories</h1>
            <p>High-performing, efficient pneumatic accessories for compressed air systems.</p>
            <button
              onClick={() => {
                handleOpen2();
              }}
            >
              Register
            </button>
          </div>
          <div className="ss-page__content-img | single-service-content-img | PneumaticImg">
            <img src={PneumaticImg} alt="" />
          </div>
        </div>
      </div>
      <div className="ss-page-desc">
        <p>
          Pneumatic fittings from Looptechnik's comprehensive variety offer
          connectivity options for a wide range of pneumatic applications. Our
          selection of pneumatic fittings includes a wide variety of items, all
          of which are simple to install and come in a variety of material
          choices and device configurations to meet your project's requirements.
          <br /> <br /> Additionally, they are able to endure a range of
          industrial applications. Our pneumatic fittings can be used in a wide
          range of applications that span many of the key industrial markets of
          today.
        </p>
        <div className="advantages">
          <h1>Our Products</h1>

          <div className="advatage-content">
            <ul>
              <li> Zero Air loss level sensing auto Drain Valve</li>
              <li> Pneumatic Quick release Couplings & Accessories</li>
              <li>
                Filter Regulator Lubricator for Compressed Air Applications
              </li>
            </ul>
          </div>
        </div>
        <div className="advantages">
          <h1>Zero Air loss level sensing auto Drain Valve</h1>

          <div className="advatage-content">
            <p>
              We offer Drainage Management System that is a complete solution to
              drainage concerns of many industries. A management system where
              the conventional drainage system is intercepted by a water control
              structure, which effectively functions as an in-line dam, allowing
              the drainage outlet to be artificially set at levels ranging from
              the soil surface to the bottom of the drains. We offer two major
              systems where in first, water level is controlled with structures
              by adding or removing logs or using float mechanism to control the
              opening and closing of a flow valve.
            </p>
          </div>
        </div>
        <div className="advantages">
          <h1>Pneumatic Quick Release Couplers</h1>

          <div className="advatage-content">
            <p>
              Pneumatic quick couplers, are primarily used for pneumatic
              applications, connecting air tools, hoses, or other implements to
              compressed air supplies. They are also used with other gases. The
              coupler half contains a shut-off valve that is automatically
              opened when a mating nipple is inserted and automatically closes
              when the nipple is removed.
            </p>
          </div>
        </div>
        <div className="advantages">
          <h1>Filter Regulator Lubricators ( FRL) </h1>

          <div className="advatage-content">
            <p>
              Air Filter Regulators are used to remove liquid water and
              particulate matter from compressed air sources. These are
              'mechanical filters' and do not remove oil vapors or chemical
              contaminants in vapor form. We offer these industrial units at
              very competitive prices making it easier for you to ensure you get
              the right pressure and clean, lubricated air to your tools! The
              PNEUMSYS FRL used in all industrial applications related to
              compressed air applications. Available in 1/4", 3/8" and 1/2" BSP.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pneumatic;
