import React, { useState, useRef,useEffect } from "react";
import Navbar from "../../components/Navbar";
import emailjs from "@emailjs/browser";
import AboutImg from "../../assets/about.png";
import Landing from "../../assets/landing2.png";
import More1 from "../../assets/more1.png";
import More2 from "../../assets/more2.png";
import SurveyImg from "../../assets/survey.png";
import Mail from "../../assets/mail.svg";
import Phone from "../../assets/phone.svg";
import Prod from "../../assets/product.png";
import Ss from "../../assets/ss.png";
import AirAduit from "../../assets/air-audit.png";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Steam from "../../assets/steam.png";
import Pneumatic from "../../assets/Pneumatic.png";
import Clean from "../../assets/clean.png";
import ProductModal from "../../Modals/ProductModal";
import RegisterModal from "../../Modals/RegisterModal";
import BrandModal from "../../Modals/BrandModal";
import Footer from "../../components/Footer";
const data = [
  {
    id: 1,
    name: "Aluminium for Compressed Air",
    path: "/aluminium",
    description:
      "Aluminium Push-Fit Piping Systems for compressed air applications. Easy, modular and corrosion resistant.",
    img: Prod,
    service: "Aluminium Piping",
    detailed:
      "Our aluminum piping solutions are cost-effective, innovative and energy efficient. They’re easy to assemble, change and expand. Aluminium pipes allow for optimal air flow, reduced energy costs, and better air quality. The push-fittings used with these pipe systems fit securely and leak far significantly less than the fittings used with threaded systems. This directly results into reduced energy use and increased plant productivity.The materials and types of fittings used offer a modular, flexible system. Our Innovative technology enables rapid and easy assembly, quick connection of components to the Aluminium pipes. In addition, the aluminum piping system is available in a broad range of sizes and fittings",
  },
  {
    id: 2,
    name: "SS Press Fit Piping",
    path: "/ss-press",
    description:
      "Quicker, safer, cleaner Stainless Steel piping installations.",
    img: Ss,
    service: "SS Press Fit Piping",
    detailed:
      "Stainless Steel piping is known for its resistance to corrosion, strength, versatility and durability. This is why stainless steel is the go-to option for many industrial applications, especially where clean air delivery is of major concern. However, stainless steel installations typically require welding or threading, which are time consuming, highly dependent on workmanship, and are prone to leakages. Also, pipes need to be thin in order to allow for welding and threading. This increases the material cost significantly.",
  },
  {
    id: 3,
    name: "Steam Generation Solutions ",
    path: "/steam",
    service: "Steam Piping",
    description: "Steam Boilers installations and turnkey solutions",
    img: Steam,
    detailed:
      "On any given day, you can find one of the several industrial boiler uses in virtually every facility or commercial building. As a matter of fact, industrial boiler systems operate very similar to the standard boiler used in residential homes and properties. The primary difference is industrial boilers are designed to provide heating solutions for much larger spaces.With larger dimensions and more formidable components, industrial boilers boast the ability to provide heating capacity for skyscrapers, entire city districts, hotels, and much more. Whenever process heat created by steam boiler systems is used — as within the pharmaceutical processing and food industry — the number of applications become exponentially greater.",
  },
  {
    id: 4,
    name: "Air Audits & Leakage Management ",
    path: "/air-audits",
    service: "Air Audits",
    description:
      "Compressed Air Flow monitoring, Ultrasonic leak detection and Overall plant performance. ",
    img: AirAduit,
    detailed:
      "We are into energy measuring, monitoring and testing solutions for compressed air, inert gases and steam. Our goal is to provide solutions for the industry to reduce consumption and improve efficiency. Looptechnik performs permanent monitoring and measurement solutions through digital flow meters. We also identify leakages in pneumatic systems using advanced ultrasonic leak detectors. By using flow meters and ultrasonic leak detectors we can identify the actual consumption of your plant also locate and quantify energy wastage.",
  },
  {
    id: 5,
    name: "Pneumatic Accessories",
    path: "/pneumatic",
    service: "Pneumatic Accessories",
    description:
      "High-performing, efficient  pneumatic accessories for compressed air systems. ",
    img: Pneumatic,
    detailed:
      "Pneumatic fittings from Looptechnik's comprehensive variety offer connectivity options for a wide range of pneumatic applications. Our selection of pneumatic fittings includes a wide variety of items, all of which are simple to install and come in a variety of material choices and device configurations to meet your project's requirements.",
  },
  {
    id: 6,
    name: "Clean Energy Solutions",
    path: "/clean-energy",
    service: "Clean Energy",
    description:
      "Affordable Renewable Energy solutions for industrial and domestic applications.   ",
    img: Clean,
    detailed:
      "We believe that Clean energy generation, paired with efficient operational practices is the way to go to obtain swift, sustainable, and practical results. We are working hard to collaborate with industry leaders from multiple countries to come up with revolutionary solutions tailored for the middle east.",
  },
];
const Home = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [currentItem, setCurrentItem] = useState({});
  const handleOpen = (item) => {
    setCurrentItem(item);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);
  const handleClose3 = () => setOpen3(false);
  const handleOpen2 = () => setOpen2(true);
  const handleOpen3 = () => setOpen3(true);

  const handleClick = (section) => {
    scroller.scrollTo(section, {
      smooth: true,
      offset: -100,
      duration: 1200,
    }); 
  };
  const form = useRef();
  const sendEmail = async (e) => {
    console.log("clicked");
    e.preventDefault(); 
    try {
      await emailjs
        .sendForm(
          `${process.env.REACT_APP_SERVICE_ID}`,
          `${process.env.REACT_APP_TEMPLATE_ID_2}`,
          form.current,
          `${process.env.REACT_APP_KEY}`
        )
        .then(
          (result) => {
            console.log(result.text);
            form.current.reset();
          },
          (error) => {
            console.log(error.text);
          }
        );
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="home-page">
        <BrandModal open={open3} handleClose={handleClose3} />
        <RegisterModal open={open2} handleClose={handleClose2} />
        {/* <div className="home-page-wrap" name="home-page-scroll">
          <Navbar handleClick={handleClick} />
          <div className="home-page-container">
            <div className="home-page-heading">
              <h1>Simple and Efficient Industrial Solutions</h1>
              <p>
                Over 10 years of providing successful solutions for industrial
                piping <br /> applications.{" "}
              </p>
            </div>
          </div>
        </div> */}
        <div className="home-page-new-container">
          <Navbar handleClick={handleClick} />
          <div
            name="home-page-new-container-flex"
            className="home-page-new-container-flex"
          >
            <div className="home-page-new-container-flex-left">
              <h1>Simple and Efficient Industrial Solutions</h1>
              <p>
                Over 10 years of providing successful solutions for industrial
                piping applications.{" "}
              </p>
              <div className="landing-btn">
                <button
                  onClick={() => {
                    setOpen2(true);
                  }}
                >
                  Get Started
                </button>
              </div>
            </div>
            <div className="home-page-new-container-flex-right">
              {/* <img src={Landing} alt="" /> */}
              <img src="https://d1029ri9zcztl7.cloudfront.net/looptechnikimg.jpg" alt="" />
            </div>
          </div>
        </div>
        <div className="home-page-more-container">
          <div className="more-container-img1 | home-more-img">
            <img src={More1} alt="" />
          </div>
          <div className="more-container-img2 | home-more-img">
            <img src={More2} alt="" />
          </div>
          <h1>
            more than <span>40+</span>
          </h1>
          <p>
            customers trust our products & installation services. We provide
            bespoke solutions that cater to their needs, and offer continued
            support post installation.
          </p>

          <button onClick={handleOpen3} className="btn | primary-btn">
            Our Clients
          </button>
        </div>
        <div name="services-page-scroll" className="our-prducts">
          <div className="container-title">
            <div className="line"></div>
            Our Products and services
          </div>
          <div className="our-products-container">
            <ProductModal
              open={open}
              handleClose={handleClose}
              currentItem={currentItem}
            />
            {data.map((item) => {
              return (
                <div className="our-products-card">
                  <div className="our-products-card-img">
                    {/* <img className={ item?.name==="Pneumatic Accessories" || "SS Press Fit Piping" ?"enlarged-image":""} src={item.img} /> */}
                    {item?.name === "Pneumatic Accessories" ? (
                      <img className="enlarged-image" src={item.img} />
                    ) : item?.name === "SS Press Fit Piping" ? (
                      <img className="enlarged-image" src={item.img} />
                    ) : (
                      <img src={item.img} />
                    )}
                  </div>
                  <div className="our-products-card-info">
                    <div className="our-products-card-info-title">
                      <h1>{item.name}</h1>
                      <p>{item.description}</p>
                    </div>
                    <button
                      onClick={() => handleOpen(item)}
                      className="btn | primary-btn"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          name="about-page-scroll"
          className="home-page-about-us | container"
        >
          <div className="container-title">
            <div className="line"></div>
            About Us
          </div>
          <div className="home-page-abput-us-left">
            <h1>Efficiency is at the Core of our Values</h1>
            <p>
              At Looptechnik, we believe in delivering efficient engineering
              solutions to all our clients.
              <br /> Our team of experts are passionate and competent in the
              design and implementation of engineering solutions in the most
              productive and cost-effective way. By ensuring that systems work
              in optimum conditions and deliver peak performance, for longer
              time periods, it results in improving efficiency and thereby
              reducing the burden on the planet’s resources. We believe this is
              the first step towards sustainable operations. <br /> <br />{" "}
              Looptechnik aims to be on the forefront in the move towards a more
              sustainable production environment. Together we can build towards
              a cleaner, greener future for generations to come.{" "}
            </p>
            {/* <button className="btn | primary-btn">Read more</button> */}
          </div>
          <div className="home-page-abput-us-right">
            <img src={AboutImg} alt="" />
          </div>
        </div>
        <div className="survey-container ">
          <div className="container-title | survey-title">
            <div className="line"></div>
            Site Survey
          </div>
          <div className="survey-container-left">
            <div className="survey-container-left-wrap">
              <h1>Need a Site Survey ?</h1>
              <p>
                Our experts are ready to help you decide on the best solutions
                for your plant. Call us to book a free site visit so that we can
                better understand your requirements.
              </p>
              <button
                onClick={() => handleClick("contact-us-scroll")}
                className="btn | white-btn"
              >
                Book Now!
              </button>
            </div>
          </div>
          <div className="survey-container-right">
            <img src={SurveyImg} alt="" />
          </div>
        </div>
        <div name="contact-us-scroll" className="contact-us-container">
          <div className="container-title">
            <div className="line"></div>
            Contact Us
          </div>
          <div className="contact-us-container-box">
            <h1>Reach Out to us</h1>

            <p>
              Give us a call or send us your requirements via email. We are
              happy to schedule a site visit or meeting to better understand and
              address your needs.
            </p>
            <div className="contact-info">
              <div className="contact-info-card">
                <div className="contact-info-card-icon">
                  <img src={Mail} alt="" />
                </div>
                <p>info@looptechnik.com</p>
              </div>
              <div className="contact-info-card">
                <div className="contact-info-card-icon">
                  <img src={Phone} alt="" />
                </div>
                <p>+971 56 133 5667</p>
              </div>
            </div>
            <div className="contact-us-inputs">
              <form ref={form} onSubmit={sendEmail} action="">
                <div className="contact-input">
                  <input required name="name" type="text" placeholder="Your Name" />
                </div>
                <div className="contact-input">
                  <input required name="email" type="text" placeholder="Your Email" />
                </div>
                <div className="contact-input">
                  <input required name="phone" type="text" placeholder="Your Phone" />
                </div>
                <div className="contact-input">
                  <input required name="occupation" type="text" placeholder="Your Occupation" />
                </div>
            <button type="submit" className="btn | primary-btn">
              Submit
            </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer handleClick={handleClick} />
    </div>
  );
};

export default Home;
