import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Home from "../pages/Home";
import AirAudits from "../pages/Products/AirAudits";
import Aluminium from "../pages/Products/Aluminium";
import { useLocation } from "react-router-dom";
import CleanEnergy from "../pages/Products/CleanEnergy";
import Pneumatic from "../pages/Products/Pneumatic";
import SsPress from "../pages/Products/SsPress";
import Steam from "../pages/Products/Steam";
const routes = [
  {
    path: "/",
    exact: true,
    element: <Home />,
    private: true,
  },
  {
    path: "/aluminium",
    exact: true,
    element: <Aluminium />,
    private: true,
  },
  {
    path: "/ss-press",
    exact: true,
    element: <SsPress />,
    private: true,
  },
  {
    path: "/steam",
    exact: true,
    element: <Steam />,
    private: true,
  },
  {
    path: "/air-audits",
    exact: true,
    element: <AirAudits />,
    private: true,
  },
  {
    path: "/pneumatic",
    exact: true,
    element: <Pneumatic />,
    private: true,
  },
  {
    path: "/clean-energy",
    exact: true,
    element: <CleanEnergy />,
    private: true,
  },
];

export default function Navigation() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
    </Routes>
  );
}
