import React, { useState } from "react";
import WhiteLogo from "../../assets/whitelogo.svg";
import Stainless from "../../assets/clean.png";
import Steam1 from "../../assets/steam1.png";
import Footer from "../../components/Footer";
import RegisterModal from "../../Modals/RegisterModal";
const CleanEnergy = () => {
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  return (
    <>
      <div className="ss-page | single-service">
        <RegisterModal
          service="Clean Energy"
          open={open2}
          handleClose={handleClose2}
        />
        <div className="logo-single">
          <img src={WhiteLogo} alt="" />
        </div>
        <div className="ss-page__content | single-service-content">
          <div className="ss-page__content-text | single-service-content-text">
            <h1>Clean Energy Solutions</h1>
            <p>Affordable Renewable Energy solutions for industrial and domestic applications.</p>
            <button
              onClick={() => {
                handleOpen2();
              }}
            >
              Register
            </button>
          </div>
          <div className="clean-page__content-img | single-service-content-img">
            <img src={Stainless} alt="" />
          </div>
        </div>
      </div>
      <div className="ss-page-desc">
        <p>
          The Middle East has a strong foundation to build a greener, more
          sustainable future. The prevalence of regular sunshine and wind, ample
          space, and great steps forward in hydrogen production and
          waste-to-energy, give the region the opportunity to dramatically
          reduce its carbon footprint.
          <br /> <br />
          Looptechnik aims to work with businesses and industries in the Middle
          East with the hope of undertaking numerous exciting projects that will
          accelerate its transition to cleaner energy.
          <br /> <br />
          We believe that Clean energy generation, paired with efficient
          operational practices is the way to go to obtain swift, sustainable,
          and practical results. We are working hard to collaborate with
          industry leaders from multiple countries to come up with revolutionary
          solutions tailored for the middle east.
          <br /> <br />
          Stay tuned for exciting news from us!
        </p>
      </div>
      <Footer />
    </>
  );
};

export default CleanEnergy;
