import React, { useState } from "react";
import WhiteLogo from "../../assets/whitelogo.svg";
import AirAudit from "../../assets/air-audit.png";
import Footer from "../../components/Footer";
import Steam1 from "../../assets/steam1.png";
import RegisterModal from "../../Modals/RegisterModal";
const AirAudits = () => {
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  return (
    <>
      <div className="ss-page | single-service">
        <RegisterModal
          open={open2}
          handleClose={handleClose2}
          service={"Air Audits"}
        />
        <div className="logo-single">
          <img src={WhiteLogo} alt="" />
        </div>
        <div className="ss-page__content | single-service-content">
          <div className="ss-page__content-text | single-service-content-text">
            <h1>
              Air Audits and Leakage <br /> Management Solutions
            </h1>
            <p>Improve your system's efficiency, saving you money, time and energy.</p>
            <button
              onClick={() => {
                handleOpen2();
              }}
            >
              Register
            </button>
          </div>
          <div className="ss-page__content-img | single-service-content-img | air-audit-img">
            <img src={AirAudit} alt="" />
          </div>
        </div>
      </div>
      <div className="ss-page-desc">
        <p>
          We are into energy measuring, monitoring and testing solutions for
          compressed air, inert gases and steam. Our goal is to provide
          solutions for the industry to reduce consumption and improve
          efficiency. Looptechnik performs permanent monitoring and measurement
          solutions through digital flow meters. We also identify leakages in
          pneumatic systems using advanced ultrasonic leak detectors. By using
          flow meters and ultrasonic leak detectors we can identify the actual
          consumption of your plant also locate and quantify energy wastage.
          <br /> <br />
          We serve all industrial markets for example automotive, glass
          manufacturing, metal processing, food and beverage and consumer goods.
          <br /> <br />
          Get real time insight in your energy consumption with our energy
          monitoring software. Use our compressed air flow meters to check
          consumption of machines, allocate costs and monitor leakage level.
        </p>
        <div className="advantages">
          <h1>What are the benefits of an air audit?</h1>

          <div className="advatage-content">
            <ul>
              <li>
                {" "}
                Air audits can help you improve the efficiency of your system,
                saving you money, time and energy.
              </li>
              <li>
                {" "}
                An audit can help our customers gain a better understanding of
                their system and its needs, helping you to make smarter
                equipment choices moving forward.{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="advantages">
          <h1>Why are air audits important?</h1>

          <div className="advatage-content">
            <p>
              Customers look for air audits for a number of reasons. Often, they
              have just installed a new machine and their current equipment is
              struggling to keep up. In other cases, a customer wants us to
              assess their whole system retrospectively before installing a new
              machine, so as to minimize impact on their existing setup. Others
              need help identifying the source of a leak that is causing their
              energy costs to soar. Whatever the reason, our team at looptechnik
              is well-versed in identifying the cause, and providing a solution
              to your air system’s problems.
            </p>
          </div>
        </div>
        <div className="advantages">
          <h1>Why is it important to rectify air leaks?</h1>

          <div className="advatage-content">
            <p>
              Leaks in a compressed air system cause more problems than are
              normally recognized. The imbalance of pressure in most air systems
              is a function of the level of unregulated demand of which leaks
              are the greatest contributor. The inability of the system to
              maintain consistent pressure throughout the header piping is also
              function of the unregulated demand. The increased power and
              elevated pressures that are required as a system ages are a result
              of a combination of factors including leaks at the point of use.
              <br /> <br />
              Leaks in an air system make it impossible to equalize pressure in
              an air system for the same reasons. When a new user enters the
              system, it is called a demand event. The air to support the event
              is removed from the header which causes the pressure to drop in
              the header from the application back to the compressors. The size
              of the drop in pressure is a function of the size of the event,
              the transmission time from the application back to the
              compressors, and the capacitance of the system.
            </p>
          </div>
        </div>
        <div className="advantages">
          <h1>How does a leak management benefit you?</h1>

          <div className="advatage-content">
            <p>
              Ultrasonic Leak Detection Audits Save you Money. A Compressed Air
              Ultrasonic Leak Detection Audit takes a peek at areas within your
              system that cost energy waste through air leakage. Take advantage
              of our Compressed Air Study to finds ways to better your system’s
              efficiency including identifying the leaks costing you money.
              <br /> <br />
              The audit takes an in-depth look at the entire system for leaks
              and gives a baseline to address “what ifs” for improvements. We
              give you recommendations and data to support return on investment.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AirAudits;
