import React, { useState } from "react";
import Logo from "../assets/logo.svg";
import Home from "../assets/home.svg";
import PhoneIcon from "../assets/phoneicon.svg";
import MailIcon from "../assets/mailimg.svg";
import Care from "../assets/care.svg";
import Insta from "../assets/instagram.svg";
import Facebook from "../assets/facebook.svg";
import Linkedin from "../assets/linkedin.svg";
import Location from "../assets/location.svg";
import WhiteLogo from "../assets/whitelogo.svg";
import RegisterModal from "../Modals/RegisterModal";
const Footer = (props) => {
  const [open2, setOpen2] = useState(false);
  const handleClose2 = () => setOpen2(false);
  const handleOpen2 = () => setOpen2(true);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  return (
    <footer>
      <RegisterModal
        service="Catalogs"
        open={open2}
        handleClose={handleClose2}
      />
      <RegisterModal
        service="Catalogs"
        open={open}
        handleClose={handleClose}
      />
      <div className="footer">
        <div className="footer-container">
          <h1>
            Need more detailed product <br /> information?
          </h1>
          <p>
            For data sheets, catalogs, and technical specifications for any of
            our products, register your interest below, and we will have them
            sent to you via email right away.
          </p>
          <button
            onClick={() => {
              handleOpen2();
            }}
            type="submit"
            className="btn | primary-btn"
          >
            Request Now
          </button>
        </div>
      </div>
      <div className="bottom-footer">
        <div className="bottom-footer-flex">
          <div className="bottom-footer-left">
            <img src={WhiteLogo} alt="" />
            <div className="location">
              <img src={Location} alt="" />
              <p>
                1 Business Centre, Meydan Free Zone, Dubai, UAE. <br /> PO Box :
                35195
              </p>
            </div>
            <div className="footer-btn | landing-btn">
              <button  onClick={() => {
              handleOpen();
            }} >Get Started</button>
            </div>
          </div>
          <div className="bottom-footer-right">
            <div className="bottom-footer-right-one">
              <h1>Quick Links</h1>
              <p
                onClick={() => {
                  props.handleClick("home-page-new-container-flex");
                }}
              >
                {" "}
                <img src={Home} alt="" /> Home
              </p>
              <p
               onClick={() => { 
                props.handleClick('contact-us-scroll')
              }}
              >
                {" "}
                <img src={Care} alt="" /> Contact Us
              </p>
              <p>
                {" "}
                <img src={MailIcon} alt="" /> <a target="_blank" rel="noopener" href="mailto: info@looptechnik.com">  info@looptechnik.com</a>
              </p>
              <p>
                {" "}
                <img src={PhoneIcon} alt="" />  <a href="tel:+971561335667">+971 56 133 5667</a> 
              </p>
            </div>
            <div className="bottom-footer-right-two | bottom-footer-right-one">
              <h1>Socials</h1>
              <div className="social-img">
                <img src={Insta} alt="" />
                <img src={Facebook} alt="" />
                <img src={Linkedin} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="copyright">
          <p>Copyright © 2023 Looptechnik, All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
