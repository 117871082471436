import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import RegisterModal from "./RegisterModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width: window.innerWidth < 1000 ? "90%" : "75%",
  p: 1.3,
};
const ProductModal = ({ open, handleClose, currentItem }) => {
  const navigate = useNavigate();
  console.log(currentItem);
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  let detailedContent = currentItem?.detailed;
  if (window.innerWidth < 700) {
    if (detailedContent && detailedContent.length > 250) {
      detailedContent = detailedContent.slice(0, 250) + "...";
    }
  }
  return (
    <>
      <RegisterModal
        service={currentItem?.service}
        open={open2}
        handleClose={handleClose2}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="modal-content">
            <div onClick={handleClose} className="cross-image">
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.75L20 20.75M20 1.75L1 20.75"
                  stroke="black"
                  stroke-width="2.23529"
                />
              </svg>
            </div>
            <div className="modal-img">
              <img src={currentItem?.img} alt="" />
            </div>
            <div className="modal-info">
              <div className="modal-info-text">
                <h1>{currentItem?.name}</h1>
                <p>{detailedContent}</p>
              </div>
              <div className="btns">
                <button
                  onClick={() => {
                    handleClose();
                    handleOpen2();
                  }}
                  className="btn | primary-btn"
                >
                  Get Started
                </button>
                <button
                  onClick={() => {
                    navigate(currentItem?.path);
                  }}
                  className="btn | secondary-btn"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default ProductModal;
