import React, { useState } from "react";
import Footer from "../../components/Footer";
import WhiteLogo from "../../assets/whitelogo.svg";
import Stainless from "../../assets/stainless.png";
import AluminiumImg from "../../assets/aluminiumimg.png";
import RegisterModal from "../../Modals/RegisterModal";
const Aluminium = () => {
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  return (
    <>
      <div className="ss-page | single-service">
        <RegisterModal
          open={open2}
          handleClose={handleClose2}
          service={"Aluminium Piping"}
        />
        <div className="logo-single">
          <img src={WhiteLogo} alt="" />
        </div>
        <div className="ss-page__content | single-service-content">
          <div className="ss-page__content-text | single-service-content-text">
            <h1>
              Aluminium Push-Fit Piping Systems <br /> for Compressed Air
            </h1>
            <p>Modular piping solutions that are built to last.</p>
            <button
              onClick={() => {
                handleOpen2();
              }}
            >
              Register
            </button>
          </div>
          <div className="ss-page__content-img | single-service-content-img">
            <img src={AluminiumImg} alt="" />
          </div>
        </div>
      </div>
      <div className="ss-page-desc">
        <p>
          Our aluminum piping solutions are cost-effective, innovative and
          energy efficient. They’re easy to assemble, change and expand.
          Aluminium pipes allow for optimal air flow, reduced energy costs, and
          better air quality. The push-fittings used with these pipe systems fit
          securely and leak far significantly less than the fittings used with
          threaded systems. This directly results into reduced energy use and
          increased plant productivity
          <br /> <br />
          The materials and types of fittings used offer a modular, flexible
          system. Our Innovative technology enables rapid and easy assembly,
          quick connection of components to the Aluminium pipes. In addition,
          the aluminum piping system is available in a broad range of sizes and
          fittings.
        </p>
        <div className="advantages">
          <h1>Key Advantages of Push-Fit Aluminium Piping: </h1>
          <div className="advatage-content">
            <div className="advantage-item">
              <h2>Quick & Easy Installation</h2>
              <p>
                Push-fit piping can be installed quickly and easily by unskilled
                labor, which reduces labor costs and allows for more efficient
                installations.No particular preparations beyond cutting,
                deburring, and chamfering are required. No special tools needed.
              </p>
            </div>
            <div className="advantage-item">
              <h2>Easy Reconfiguration.</h2>
              <p>
                When repairs or upgrades are needed, grooved and push-fit piping
                can be easily disassembled and reassembled. The fittings are
                endlessly reusable. Compared to conventional welded piping,
                which often requires cutting and welding for repairs or
                upgrades, push-fit allows for more flexibility and savings in
                the long term.
              </p>
            </div>
            <div className="advantage-item">
              <h2>No Leakages.</h2>
              <p>
                Push fit piping allows for minimal leaks as it is designed to
                avoid any gaps between the pipe and the fitting, ensuring a
                tight, secure seal. This can be critical in industrial
                applications where even small leaks can cause significant
                damage.
              </p>
            </div>
            <div className="advantage-item">
              <h2>No Corrosion.</h2>
              <p>
                Aluminium is not prone to corrosion as opposed to conventional
                GI or MS piping. This is why our aluminium installations are
                typically provided with a 25 year warranty against corrosion.
                Suitable for High Pressure. Our Aluminium pipes a designed to
                withstand operating pressures ranging from 16 to 70bar, whereas
                our fittings are rated for 270 bar!
              </p>
            </div>
            <div className="advantage-item">
              <h2>Low Pressure Drop.</h2>
              <p>
                The smooth mirror-like finish of the inner walls of our
                Aluminium pipes allow for laminar air flow throughout the
                installation. This allows for minimal energy loss and efficient
                operating conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Aluminium;
