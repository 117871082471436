import React, { useState } from "react";
import Logo from "../assets/logopng.png";
import RegisterModal from "../Modals/RegisterModal";
const Navbar = (props) => {
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  const [nav, setnav] = useState(false);
  return (
    <div className="navbar">
      <RegisterModal open={open2} handleClose={handleClose2} />
      <div className="navbar__logo">
        <img src={Logo} alt="" />
      </div>
      <div className="navbar__links">
        <p
        onClick={() => { 
          props.handleClick('home-page-scroll')
        }}
        >Home</p>
        <p
        onClick={() => { 
          props.handleClick('about-page-scroll')
        }}
        >About</p>
        <p
        onClick={() => { 
          props.handleClick('services-page-scroll')
        }}
        >Services</p>
        <p
        onClick={() => { 
          props.handleClick('contact-us-scroll')
        }}
        >Contact</p>
      </div>
      <div className="nav-btn">
        <button
          onClick={() => {
            handleOpen2();
          }}
          className="btn | primary-btn"
        >
          Get Started
        </button>
      </div>
      <div
        onClick={() => {
          setnav(true);
         
        }}
        className="menu-bar"
      >
        <svg
          width="32"
          height="15"
          viewBox="0 0 32 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.286133"
            width="31.428"
            height="2.14282"
            rx="1.07141"
            fill="#5E5E5E"
          />
          <rect
            x="11.2178"
            y="6.42871"
            width="20.4965"
            height="2.14282"
            rx="1.07141"
            fill="#5E5E5E"
          />
          <rect
            x="5.75195"
            y="12.8564"
            width="25.9622"
            height="2.14282"
            rx="1.07141"
            fill="#5E5E5E"
          />
        </svg>
      </div>
      {nav ? (
        <div className="open-menu">
          <div className="open-menu__links">
            <p
              onClick={() => {
                setnav(false);
                props.handleClick('home-page-scroll')
              }}
            >
              Home
            </p>
            <p
              onClick={() => {
                setnav(false);
                props.handleClick('about-page-scroll')
              }}
            >
              About
            </p>
            <p
              onClick={() => {
                setnav(false);
                props.handleClick('services-page-scroll')
              }}
            >
              Services
            </p>
            <p
              onClick={() => {
                setnav(false);
                props.handleClick('contact-us-scroll')
              }}
            >
              Contact
            </p>
            <div className="nav-btn">
              <button
                onClick={() => {
                  handleOpen2();
                }}
                className="btn | primary-btn"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Navbar;
