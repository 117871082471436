import React, { useState } from "react";
import WhiteLogo from "../../assets/whitelogo.svg";
import Stainless from "../../assets/steam.png";
import Steam1 from "../../assets/steam1.png";
import RegisterModal from "../../Modals/RegisterModal";
import Footer from "../../components/Footer";
const Steam = () => {
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  return (
    <>
      <div className="ss-page | single-service">
        <RegisterModal
          open={open2}
          handleClose={handleClose2}
          service="Steam Piping"
        />
        <div className="logo-single">
          <img src={WhiteLogo} alt="" />
        </div>
        <div className="ss-page__content | single-service-content">
          <div className="ss-page__content-text | single-service-content-text">
            <h1>Steam Generation Solutions</h1>
            <p>Steam Boilers installations and turnkey solutions</p>
            <button
              onClick={() => {
                handleOpen2();
              }}
            >
              Register
            </button>
          </div>
          <div className="ss-page__content-img  | single-service-content-img ss-page__content-img-steam">
            <img src={Stainless} alt="" />
          </div>
        </div>
      </div>
      <div className="ss-page-desc">
        <p>
          On any given day, you can find one of the several industrial boiler
          uses in virtually every facility or commercial building. As a matter
          of fact, industrial boiler systems operate very similar to the
          standard boiler used in residential homes and properties. The primary
          difference is industrial boilers are designed to provide heating
          solutions for much larger spaces.
          <br /> <br />
          With larger dimensions and more formidable components, industrial
          boilers boast the ability to provide heating capacity for skyscrapers,
          entire city districts, hotels, and much more. Whenever process heat
          created by steam boiler systems is used — as within the pharmaceutical
          processing and food industry — the number of applications become
          exponentially greater.
        </p>
        <div className="advantages">
          <h1>
            Industrial boilers are required primarily in these
            industries in the middle east :{" "}
          </h1>

          <div className="advatage-content">
            <ul>
              <li> Laundries</li>
              <li> Hotels</li>
              <li> Hospitals and Healthcare</li>
              <li> Food processing and packaging</li>
            </ul>
          </div>
        </div>
        <p>
          Looptechnik supplies, installs and maintains industrial steam boilers.
          We have partnered with Italian Boiler makers Tecnotermica Srl. , who
          are an internationally recognized manufacturer of Pressure Vessels and
          associated equipment utilised for Steam and Hot water plant . We have
          supplied and installed the complete Steam Boiler and Heating systems
          with all boiler house accessories.
          <br /> <br />
          Our experienced, energetic teams provide unrivaled service in the
          areas of facilities management, specialist electrical installations,
          hospitals, Universities, hotels, housing refurbishments, data centre
          consultancy and technology asset management solutions.
          <br />
          <br />
          Our commitment to delivering a competitive advantage to our clients
          through performance, quality and efficiency make us a preferred
          supplier for Facilities management requirements
        </p>
      </div>
      <Footer />
    </>
  );
};

export default Steam;
