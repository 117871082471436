import React, { useState } from "react";
import WhiteLogo from "../../assets/whitelogo.svg";
import Stainless from "../../assets/sspress.png";
import RegisterModal from "../../Modals/RegisterModal";
import Footer from "../../components/Footer";
const SsPress = () => {
  const [open2, setopen2] = useState(false);
  const handleOpen2 = () => setopen2(true);
  const handleClose2 = () => setopen2(false);
  return (
    <>
      <div className="ss-page | single-service">
        <RegisterModal
          service="SS Press Fit Piping"
          open={open2}
          handleClose={handleClose2}
        />
        <div className="logo-single">
          <img src={WhiteLogo} alt="" />
        </div>
        <div className="ss-page__content | single-service-content">
          <div className="ss-page__content-text | single-service-content-text">
            <h1>
              Stainless Steel Press- <br />
              fit Piping Solutions
            </h1>
            <p>
              Clean, easy and robust piping solutions suitable for a variety of
              applications
            </p>
            <button
              onClick={() => {
                handleOpen2();
              }}
            >
              Register
            </button>
          </div>
          <div className="ss-page__content-img | single-service-content-img">
            <img src={Stainless} alt="" />
          </div>
        </div>
      </div>
      <div className="ss-page-desc">
        <p>
          Stainless Steel piping is known for its resistance to corrosion,
          strength, versatility and durability. This is why stainless steel is
          the go-to option for many industrial applications, especially where
          clean air delivery is of major concern. <br />
          <br /> However, stainless steel installations typically require
          welding or threading, which are time consuming, highly dependent on
          workmanship, and are prone to leakages. Also, pipes need to be think
          in order to allow for welding and threading. This increases the
          material cost significantly. <br />
          <br />
          The introduction of press-fit solutions for stainless steel has
          revolutionized the industry. Press-fit technology allows for
          thin-walled SS pipes, since there is no need for welding. Installation
          is much easier, consumes less time, and does not require hot work
          permits. Press-fit ensures seamless, leak proof connections at every
          joint. <br /> Unlike the conventional soldering method, press fit
          requires no heat or solder wire and the fittings do not have to be
          welded to the pipe. Once the fitting is in place a special power tool
          is used to press the fitting and this creates a solid joint between
          the press fitting and the pipe.
        </p>
        <div className="advantages">
          <h1>Advantages of Press-fit Piping </h1>

          <div className="advatage-content">
            <div className="advantage-item">
              <h2>No Skilled Labor Required.</h2>
              <p>
                Grooved and press fit piping can be installed quickly and easily
                by unskilled labor, which reduces labor costs and allows for
                more efficient installations.
              </p>
            </div>
            <div className="advantage-item">
              <h2>Easy Reconfiguration.</h2>
              <p>
                When repairs or upgrades are needed, grooved and push-fit piping
                can be easily disassembled. Compared to conventional welded
                piping, which often requires cutting and welding for repairs or
                upgrades, press-fit allows for more flexibility and easier and
                cleaner repairs and replacements. No Leakages.
                <br /> <br />
                Press fit piping allows for minimal leaks as it is designed to
                avoid any gaps between the pipe and the fitting, ensuring a
                tight, secure seal. This can be critical in industrial
                applications where even small leaks can cause significant
                damage.
              </p>
            </div>
            <div className="advantage-item">
              <h2>No Corrosion.</h2>
              <p>
                Stainless Steel is not prone to corrosion as opposed to other
                metals. This gives stainless steel the edge and is suitable for
                a variety of applications, including water delivery,
                pharmaceutical or food grade applications.
              </p>
            </div>
            <div className="advantage-item">
              <h2>Value Retention.</h2>
              <p>
                When you use stainless steel pipes, you are buying a durable
                product that will last your business for decades. It is a
                reliable material that is easy to maintain and install.
                Stainless steel is low-maintenance, and due to its
                corrosion-resistant properties, it is unlikely they will need to
                be replaced for decades.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default SsPress;
