import React, { useEffect, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import WhiteLogo from "../assets/whitelogo.svg";
import Circle from "../assets/circle.svg";
import Review from "../assets/review2.png";

import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { successToast } from "../utils/toast";
const data = [
  {
    name: "Aluminium Piping",
  },
  {
    name: "Steam Piping",
  },
  {
    name: "Air Audits",
  },
  {
    name: "SS Press Fit Piping",
  },
  {
    name: "Pneumatic Accessories",
  },
  {
    name: "Clean Energy",
  },
  {
    name: "Catalogs",
  },
];
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width:
    window.innerWidth > 1400
      ? "1300px"
      : window.innerWidth > 900
      ? "1000px"
      : "90%",
  p: 1.3,
};
const RegisterModal = ({ open, handleClose, service }) => {
  const navigate = useNavigate();
  const [openSelect, setopenSelect] = useState(false);
  const [selected, setselected] = useState(0);
  useEffect(() => {
    if (service) {
      const index = data.findIndex((item) => item.name === service);
      setselected(index);
      console.log(index);
    }
  }, [service, data]);
  const form = useRef();

  const sendEmail = async (e) => {
    e.preventDefault();
    const hiddenInput = document.createElement("input");
    hiddenInput.setAttribute("type", "hidden");
    hiddenInput.setAttribute("name", "service");
    hiddenInput.setAttribute("value", data[selected].name);
    form.current.appendChild(hiddenInput);
    try {
      await emailjs
        .sendForm(
          `${process.env.REACT_APP_SERVICE_ID}`,
          `${process.env.REACT_APP_TEMPLATE_ID}`,
          form.current,
          `${process.env.REACT_APP_KEY}`
        )
        .then(
          (result) => {
            // console.log(result.text);
            form.current.reset();
            successToast("Thank you for registering with us.");
          },
          (error) => {
            // console.log(error.text);
          }
        );
    } catch (error) {
      // console.error(error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div className="register-modal">
        <div onClick={handleClose} className="cross-image">
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.75L20 20.75M20 1.75L1 20.75"
                  stroke="black"
                  stroke-width="2.23529"
                />
              </svg>
            </div>
          <div className="register-modal-left">
            <div className="modal-logo">
              <img src={WhiteLogo} alt="" />
            </div>
            <div className="modal-text">
              <h1>We’ve got the best solutions for your needs. </h1>
              <p>
                Let us know what you’re looking for and we’ll get in touch with
                you at the earliest.
              </p>
            </div>
            <div className="review-card">
              <div className="review-text">
                <p>
                  We used Looptechnik to fit aluminum air compressor lines in
                  our workshop. The service from the team was top notch and the
                  quality of materials used is very good.
                </p>
                <div className="review-profile">
                  {/* <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.7997 26.9997C26.2179 26.9997 29.7995 23.4181 29.7995 18.9999C29.7995 14.5817 26.2179 11 21.7997 11C17.3815 11 13.7998 14.5817 13.7998 18.9999C13.7998 23.4181 17.3815 26.9997 21.7997 26.9997Z" fill="white"/>
<path d="M22.5 29C33.857 29 43.5 33.122 43.5 37L40 43H5L2 36C2 32.122 11.143 29 22.5 29Z" fill="white"/>
<rect x="1" y="1" width="43" height="43" rx="10" stroke="white" stroke-width="2"/>
</svg> */}
                  <img src={Review} alt="" />

                  <div className="review-profile-text">
                    <h1>Sanjay</h1>
                    <p>Project 11, Porsche Specialists</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="register-modal-right">
            <h1>Register</h1>
            <form ref={form} onSubmit={sendEmail} action="">
              <div className="register-inputs">
                <div className="register-input">
                  <p>Looking for?</p>
                  {openSelect ? (
                    <div className="register-open-select">
                      {data.map((item, index) => {
                        return (
                          <p
                            onClick={() => {
                              setopenSelect(false);
                              setselected(index);
                            }}
                            className={
                              selected === index ? "active-select" : ""
                            }
                            key={index}
                          >
                            {item.name}
                          </p>
                        );
                      })}
                    </div>
                  ) : null}
                  <div
                    onClick={() => {
                      setopenSelect(true);
                    }}
                    className="select-service"
                  >
                    <img src={Circle} alt="" />
                    {data[selected].name}
                  </div>
                </div>
                <div className="register-input">
                  <p>Email</p>
                  <input
                    type="email"
                    name="email"
                    required
                    placeholder="yourmail@gmail.com"
                  />
                </div>
                <div className="register-input">
                  <p>Phone</p>
                  <input
                    name="phone"
                    type="phone"
                    placeholder="your phone no."
                  />
                </div>
                <div className="register-input">
                  <p>Address</p>
                  <input
                    name="address"
                    required
                    type="text"
                    placeholder="your address"
                  />
                </div>
              </div>
              <div className="register-btn">
                <button type="submit">Register</button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default RegisterModal;
