import React,{useState} from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Brands from "../assets/brands.png"
import Brands2 from "../assets/brands2.png"
import RegisterModal from "./RegisterModal";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  border: "none",
  borderRadius: 2,
  width:  "85%",
  p: window.innerWidth > 600 ? 4 : 1,
};
const BrandModal = ({ open, handleClose }) => {
  return (
    <> 
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      
      <Box sx={style}>
         <div className="brand-modal">
         <div onClick={handleClose} className="cross-image">
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1.75L20 20.75M20 1.75L1 20.75"
                  stroke="black"
                  stroke-width="2.23529"
                />
              </svg>
            </div>
          <h1>Brands who trust us</h1>
          {window.innerWidth > 600 ? <img src={Brands} alt="" /> : <img src={Brands2} alt="" />}
          {/* <img src={Brands} alt="" /> */}
         </div>
      </Box>
    </Modal>
    </>
  );
};

export default BrandModal;
